import React from "react"

import "./style.scss"
import phoneImg from "../images/cell-phone-repair.webp"
import "../styles/styles.css"
import { FaCaretDown } from "react-icons/fa"

import Navbar from "./navbar"

const Header = (props, { siteTitle }) => (
  <section className="hero gradientBg  headerBackgroundWrapper">
    <Navbar serviceCity={props.serviceCity} currentPage={props.currentPage} />
    <div className="hero-body">
      <div className="container center">
        <div className="columns headerContent">
          <div className="column ">
            <article className="media">
              <div className="media-content">
                <div className="content" style={{ paddingLeft: 30 + "px" }}>
                  <h1 className="is-size-2 has-text-white headerText">
                    {props.uniqueHeader
                      ? props.uniqueHeader
                      : props.repairType
                      ? props.repairType + " Repair Service in "
                      : "iPhone Repair in "}
                    {props.uniqueHeader
                      ? ""
                      : props.serviceCity
                      ? props.serviceCity
                      : "Portland, OR"}
                  </h1>
                  <p className="subtitle has-text-white is-size-4">
                    Apple, Android, Tablets and Cell Phone Repairs
                  </p>
                  <div style={{ paddingLeft: 20 + "px" }}>
                    <a
                      className="button is-link is-inverted is-outlined is-medium"
                      href="tel:9713193194"
                    >
                      CALL (971) 319-3194
                    </a>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div className="column" style={{ marginTop: "-60px" }}>
            <img
              className="ls-s-1"
              src={phoneImg}
              alt="Phone"
              style={{ maxWidth: 90 + `%` }}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="headerArrorDownWrapper ">
      <a href="#main">
        <FaCaretDown
          size="100px"
          color="#5746ed"
          className="headerArrorDown animationMoveCaretClass"
        />
      </a>
    </div>
  </section>
)

export default Header
