import React, { useState } from 'react';
import { Link } from 'gatsby';

import '../styles/styles.css';
import './style.scss';
import {
  FaBars
} from 'react-icons/fa';



const Navbar = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisible = () => {
    setIsVisible(!isVisible);
  };


  return (
  <div className='navBarWrapper'>
  <div className='hero-head is-hidden-mobile'>
    <nav className='navbar'>
      <div className='container'>
        <div className='navbar-brand'>
          <Link
          className='navbar-item'
          to={'/'}
          state={{ serviceCity: props.serviceCity ? props.serviceCity : "Portland, OR" }}
          >
            <h3 style={{ fontSize: 1.3 + `rem`, color: `#FFF` }}>
              PhoneFix <span style={{ fontWeight: 700 }}>PRO</span>
            </h3>
          </Link>
        </div>
        <div id='navbarMenuHeroA' className='navbar-menu'>
          <div className='navbar-end navbarButtonsWrapper'>
            <span className='navbar-item'>
              <Link
              className='button is-link is-inverted is-outlined'
              id={props.currentPage === 'repairs' ? 'navbarCurrentSpan' : ''}
                to={`/cell-phone-repair`}
                state={{ serviceCity: props.serviceCity ? props.serviceCity : "Portland, OR" }}
              >
                REPAIRS
              </Link>
            </span>
            <span className='navbar-item'>
              <Link
                className='button is-link is-inverted is-outlined'
                id={props.currentPage === 'products' ? 'navbarCurrentSpan' : ''}
                to={'/phone-accessories'}
                state={{ serviceCity: props.serviceCity ? props.serviceCity : "Portland, OR" }}
              >
                PRODUCTS
              </Link>
            </span>
            <span className='navbar-item'>
              <Link
                className='button is-link is-inverted is-outlined'
                id={props.currentPage === 'about' ? 'navbarCurrentSpan' : ''}
                to={'/about-us'}
                state={{ serviceCity: props.serviceCity ? props.serviceCity : "Portland, OR" }}
              >
                ABOUT
              </Link>
            </span>
            <span className='navbar-item'>
              <Link
                className='button is-link is-inverted is-outlined'
                id={props.currentPage === 'contact' ? 'navbarCurrentSpan' : ''}
                to={'/contact'}
                state={{ serviceCity: props.serviceCity ? props.serviceCity : "Portland, OR" }}
              >
                CONTACT
              </Link>
            </span>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <div className="mobileHeaderLogo">
    <Link className='navbar-item' 
    to={'/'} 
    state={{ serviceCity: props.serviceCity ? props.serviceCity : "Portland, OR" }}>
      <h3 style={{ fontSize: 1.3 + `rem`, color: `#FFF` }}>
        PhoneFix <span style={{ fontWeight: 700 }}>PRO</span>
      </h3>
    </Link>
  </div>
    <div className="navMobileBarsWrapper"> 
      <span onClick={toggleVisible} onKeyDown={toggleVisible} role='button' aria-label="Show menu" tabIndex={0} style={{position:'relative'}}><FaBars size='45px' color="#ffffff"  /></span>
    </div>
    
    <div className={isVisible ? 'navMobileMenu' : 'navMobileMenu mobileMenuHidden'}>
      <Link 
      className='navMobileMenuLink' 
      id={props.currentPage === 'repairs' ? 'navbarCurrentSpan' : ''}
      to={`/cell-phone-repair`}
      state={{ serviceCity: props.serviceCity ? props.serviceCity : "Portland, OR" }}>
          REPAIRS
      </Link>
      <Link 
      className='navMobileMenuLink' 
      id={props.currentPage === 'products' ? 'navbarCurrentSpan' : ''}
      to={`/phone-accessories`}
      state={{ serviceCity: props.serviceCity ? props.serviceCity : "Portland, OR" }}>
          PRODUCTS
      </Link>
      <Link 
      className='navMobileMenuLink' 
      id={props.currentPage === 'about' ? 'navbarCurrentSpan' : ''}
      to={`/about-us`}
      state={{ serviceCity: props.serviceCity ? props.serviceCity : "Portland, OR" }}>
          ABOUT
      </Link>
      <Link 
      className='navMobileMenuLink' 
      id={props.currentPage === 'contact' ? 'navbarCurrentSpan' : ''}
      to={`/contact`}
      state={{ serviceCity: props.serviceCity ? props.serviceCity : "Portland, OR" }}>
          CONTACT
      </Link>
    </div>

  </div>
  );
}

export default Navbar;
