import React from 'react';

import './style.scss';
import Helmet from './helmet';
import Header from './header';

import Footer from './footer';

const Layout = props => {
  return (
    <div>
      <Helmet />
      <Header serviceCity={props.serviceCity} repairType={props.repairType} currentPage={props.currentPage} uniqueHeader={props.uniqueHeader}/>
      <div id="main">{props.children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
