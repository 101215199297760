import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { FaFacebook, FaLinkedin, FaInstagram, FaGoogle } from "react-icons/fa"

import "./style.scss"
import "../styles/styles.css"

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            twitter
          }
        }
      }
    `}
    render={data => (
      <div className="has-background-grey-dark has-text-white">
        <div className="container">
          <section className="section">
            <div className="columns is-multiline">
              <div className="column is-one-third">
                <a href="https://phonefixpro.net">
                  <h3 style={{ fontSize: 1.3 + `rem`, color: `#FFF` }}>
                    PhoneFix <span style={{ fontWeight: 700 }}>PRO</span>
                  </h3>
                </a>
                <p className="has-text-grey-lighter is-small">
                  iPhone Screen Repair in Portland OR, Broken Screen or Cracked
                  Screen stop by PhoneFix PRO to get your phone fixed. We repair
                  Apple, Samsung, Google Pixel, and other Android Tablets and
                  Phones.
                </p>

                <div className="desktopSocialIconsWrapper">
                  <a
                    href="https://www.facebook.com/PhoneFixProPDX"
                    className="desktopSocilIconLink"
                  >
                    <FaFacebook className="desktopSocilIconImg" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/phonefix-pro-portland"
                    className="desktopSocilIconLink"
                  >
                    <FaLinkedin className="desktopSocilIconImg" />
                  </a>
                  <a
                    href="https://www.instagram.com/phonefixpdx/"
                    className="desktopSocilIconLink"
                  >
                    <FaInstagram className="desktopSocilIconImg" />
                  </a>
                  <a
                    href="https://g.page/cell-phone-repairs-portland?gm"
                    className="desktopSocilIconLink"
                  >
                    <FaGoogle className="desktopSocilIconImg" />
                  </a>
                </div>
              </div>
              <div className="column is-one-third">
                <h3 style={{ fontWeight: 700 }}>Location</h3>
                <address>
                  2201 Lloyd Center,
                  <br /> Portland, OR 97232 <br />
                </address>
                <span>
                  Note: We're in Lloyd Center Mall on 1st Floor by Ice Rink{" "}
                </span>
                <br />
                <span>
                  Phone: <a href="tel:9713193194">(971) 319-3194</a>
                </span>
              </div>
              <div className="column is-one-third">
                <h3 style={{ fontWeight: 700 }}>Hours</h3>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                    <p>Mon</p>
                    <p>Tue</p>
                    <p>Wed</p>
                    <p>Thu</p>
                    <p>Fri</p>
                    <p>Sat</p>
                    <p>Sun</p>
                  </div>
                  <div>
                    <p>11:00 am - 7:00 pm</p>
                    <p>11:00 am - 7:00 pm</p>
                    <p>11:00 am - 7:00 pm</p>
                    <p>11:00 am - 7:00 pm</p>
                    <p>11:00 am - 7:00 pm</p>
                    <p>Closed</p>
                    <p>11:00 am - 6:00 pm</p>
                  </div>
                </div>
                <div className="mobileSocialIconsWrapper">
                  <a
                    className="mobileSocilIconLink"
                    href="https://www.facebook.com/PhoneFixProPDX"
                  >
                    <FaFacebook className="mobileSocilIconImg" />
                  </a>
                  <a
                    className="mobileSocilIconLink"
                    href="https://www.linkedin.com/company/phonefix-pro-portland"
                  >
                    <FaLinkedin className="mobileSocilIconImg" />
                  </a>
                  <a
                    className="mobileSocilIconLink"
                    href="https://www.instagram.com/phonefix.pro/"
                  >
                    <FaInstagram className="mobileSocilIconImg" />
                  </a>
                  <a
                    href="https://g.page/cell-phone-repairs-portland?gm"
                    className="mobileSocilIconLink"
                  >
                    <FaGoogle className="mobileSocilIconImg" />
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
        <footer className="footer has-background-grey-darker has-text-white">
          <div className="content has-text-centered">
            <p className="is-size-5">
              Copyright All Rights Reserved ©{" "}
              <span id="footerCurrentYear">{new Date().getFullYear()}</span> -{" "}
              <a href="https://phonefixpro.net" style={{ color: "white" }}>
                PhoneFix Pro - Best iPhone Repair in Portland, OR
              </a>
            </p>
          </div>
        </footer>
        <script
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '234992144886593');
        fbq('track', 'PageView');`,
          }}
        />
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src="https://www.facebook.com/tr?id=234992144886593&ev=PageView&noscript=1"
            alt="Facebook Pixel "
          />
        </noscript>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'G-XXV8B5W2BG');
              `,
          }}
        ></script>

        <script
          dangerouslySetInnerHTML={{
            __html: `
            if(window.location.host.match(/phonefixpro.net/)){
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2226017,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          }`,
          }}
        ></script>
      </div>
    )}
  />
)

export default Footer
